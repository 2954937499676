import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const desktop = [
  {
    path: '/',
    name: 'Home',
    // component: () => import(/* webpackChunkName: "index" */ '../views/' + tmp + '/home.vue')
    component: () => import(/* webpackChunkName: "index" */ '../views/desktop/home.vue')
  }
]
const mobile = [
  {
    path: '/',
    name: 'Home',
    // component: () => import(/* webpackChunkName: "index" */ '../views/' + tmp + '/home.vue')
    component: () => import(/* webpackChunkName: "indexMobile" */ '../views/mobile/home.vue')
  }
]

const route = window.device.type === 'desktop' ? desktop : mobile
const routes = [
  ...route,
  {
    path: '*',
    name: '404',
    redirect: '/'
  }
]

const router = new VueRouter({
  routes
})

export default router
