/**
 * Append gtag script to DOM
 * @param  {String} script src
 * @param  {String} id      script id
 */
export function appendScript (src, id) {
  const s = document.createElement('script')
  s.id = id
  s.async = true
  s.src = src
  var n = document.getElementsByTagName('head')[0]
  if (!n) n = document.body
  n.appendChild(s)
}
/**
 * initialize gtag
 * @param  {String} src GA_TRACKING_ID
 * @param  {Object} opts  {debug,scriptId}
 */
function initialize (src, opts) {
  const w = window
  if (w.gtag) return
  appendScript(src, opts.scriptId || 'gtagjs')
  w.dataLayer = w.dataLayer || []
  const gtag = (w.gtag =
        w.gtag ||
        function () {
          w.dataLayer.push(arguments)
        })
  const gtagOpt = opts.gtagOpt || {}
  gtag('js', new Date())
  gtag('config', opts.trackId, gtagOpt)
}

/**
 * set page path and send page_view event
 * @param  {String} pathPath
 * @param  {String} src
 * @param  {Object} opts
 */
export function configPagePath (pathPath, src, opts) {
  initialize(src, opts)
  window.gtag('config', src, { page_path: pathPath })
}

function log (url) {
  console.log(`set page path to: ${url}`)
}

export function gtagReport (router, GA_TRACKING_ID, opts = {}) {
  if (typeof router === 'function') {
    router(url => {
      configPagePath(url, GA_TRACKING_ID, opts)
      if (opts.debug) log(url)
    })
  } else {
    router.afterEach(to => {
      configPagePath(to.fullPath, GA_TRACKING_ID, opts)
      if (opts.debug) log(to.fullPath)
    })
  }
}
