import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  getters: {
    token: state => state.token,
    supportId: state => state.supportId
  },
  state: {
    token: '',
    supportId: 0
  },
  mutations: {
    SET_USER (state, payload) {
      Object.assign(state, payload)
      // console.log(state)
    },
    UPLOAD_EVENT (_, event) {
      console.log('打点：' + event)
      window.gtag('event', event)
      window.fbq('trackCustom', event)
    }
  },
  actions: {},
  plugins: [createPersistedState()] // 添加插件
})
