export function init () {
  // 获取屏幕宽度
  const html = document.documentElement
  let width = html.clientWidth
  let height = html.clientHeight

  // 设计稿的宽度
  if (window.device.type !== 'desktop') {
    const designWidth = 750
    const designHeight = 1334
    const tempW = (height * designWidth) / designHeight
    const tempH = (width * designHeight) / designWidth
    // 手机稿的宽度
    // designWidth = 640
    if (tempW < width) {
      width = tempW
    } else {
      height = tempH
    }
    html.style.fontSize = width / 10 + 'px'
  } else {
    const designWidth = 1920
    const designHeight = 1080
    const tempW = (height * designWidth) / designHeight
    const tempH = (width * designHeight) / designWidth
    console.log(tempW, tempH, 'WH')
    // if (tempW < width) {
    //   width = tempW
    // } else {
    //   height = tempH
    // }
    // 设置根元素字体大小。此时为宽的10等分
    html.style.fontSize = width / 10 + 'px'
  }
}
