/* eslint-disable */

import 'current-device'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import 'normalize.css/normalize.css'
import './styles/index.scss'
import { init } from './utils/init'
import { getUrlParam } from './utils/index'
import { appendScript } from '@/utils/dataReport'
// VueFullPage
import VueFullPage from 'vue-fullpage.js'
// vue-awesome-swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import 'swiper/swiper-bundle.css'
// video 视频播放组件
import VideoPlayer from 'vue-video-player'
// vue lazy-load
// import VueLazyload from 'vue-lazyload'
// element-ui
import { Dialog, MessageBox } from 'element-ui'
import './vant/vant'
// const trackId = 'G-R374W8QT9R'
// gtagReport(
//   set => set(location.pathname),
//   'https://www.googletagmanager.com/gtag/js?id=' + trackId,
//   {
//     debug: false,
//     trackId
//   }
// )
const shareUrl = document.querySelector('meta[name=ogurl]')
shareUrl.content = window.location.href
window.fbAsyncInit = function () {
  window.FB.init({
    appId: '309953680838524',
    autoLogAppEvents: true,
    xfbml: true,
    version: 'v2.12'
  })
  window.FB.AppEvents.logPageView()
}
// facebook-js
appendScript('https://connect.facebook.net/en_US/sdk.js', 'facebook-jssdk')
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
SwiperClass.use([Pagination, Mousewheel, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))
Vue.use(VueFullPage)
  .use(VueAwesomeSwiper)
  .use(VideoPlayer)
  // .use(VueLazyload)
  .use(Dialog)
Vue.config.productionTip = false
// 获取屏幕宽度
init()
// console.log(init)
if (window.device.type !== 'desktop') {
  // // 监听手机旋转的事件的时机，重新设置
  window.addEventListener('orientationchange', init())
} else {
  // 监听手机窗口变化，重新设置
  window.addEventListener('resize', init)
}
Vue.prototype.$alert = MessageBox.alert
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

const type = getUrlParam('abtest') || '1'
window.gtag = function gtag() { window.dataLayer.push(arguments) }
function fbInit(f, b, e, v, n, t, s) {
  if (f.fbq) return; n = f.fbq = function () {
    n.callMethod
      ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
  }
  if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0'
  n.queue = []; t = b.createElement(e); t.async = !0
  t.src = v; s = b.getElementsByTagName(e)[0]
  s.parentNode.insertBefore(t, s)
}
fbInit(window, document, 'script',
  'https://connect.facebook.net/en_US/fbevents.js')

if (type === '1') {
  appendScript('https://www.googletagmanager.com/gtag/js?id=G-R374W8QT9R', 'ga')
  window.dataLayer = window.dataLayer || []
  window.gtag('js', new Date())
  window.gtag('config', 'G-R374W8QT9R')
  // appendScript('https://www.googletagmanager.com/gtag/js?id=AW-346109571', 'ga-ads')
  window.fbq('init', '976373733498341')
  window.fbq('track', 'PageView')
}
else if (type === '2') {
  appendScript('https://www.googletagmanager.com/gtag/js?id=G-0R2V796VEK', 'ga')
  window.dataLayer = window.dataLayer || []
  window.gtag('js', new Date())
  window.gtag('config', 'G-0R2V796VEK')
  // appendScript('https://www.googletagmanager.com/gtag/js?id=AW-346109571', 'ga-ads')
  window.fbq('init', '691059956206340')
  window.fbq('track', 'PageView')
} else {
  appendScript('https://www.googletagmanager.com/gtag/js?id=G-728TKTQV0J', 'ga')
  window.dataLayer = window.dataLayer || []
  window.gtag('js', new Date())
  window.gtag('config', 'G-728TKTQV0J')
  // appendScript('https://www.googletagmanager.com/gtag/js?id=AW-346109571', 'ga-ads')
  window.fbq('init', '362516079041176')
  window.fbq('track', 'PageView')
}
