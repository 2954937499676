<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  created () {
  }
}
</script>
<style>
.el-dialog{
  background: transparent;
  box-shadow:none;
}
.el-popup-parent--hidden {
  padding-right: 0 !important;
 }
.el-dialog__header{
  display: none;
}
.el-dialog__body{
  padding: 0;
}
</style>
<style lang="scss">

</style>
